/** @format */

div {
  .json {
    // background-color: aqua;

    .variable-value {
      // display: block !important;

      div {
        display: block !important;

        .string-value {
          word-wrap: break-word;
        }
      }
    }
  }
}

.button-bar {
  margin: 0.5rem 0.2rem;
}

.TableBody {
  width: 100%;
  height: 85vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
