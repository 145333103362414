.drag-and-drop-area {
  border: 4px dashed #db2828;
  padding: 5em;
}

.text-center {
  text-align: center;
}

.disabled.drag-and-drop-area {
  border: 4px dashed #cccccc;
  padding: 5em;
}

.disabled.drag-and-drop-area > p {
  color: #cccccc;
}