.list-item-id {
  font-size: x-small;
  color: gray;
}

.list-item-id:hover {
  color: black;
  font-weight: bold;
  cursor: pointer;
}

.list-item-createdat {
  font-size: x-small;
  color: gray;
}

.list-item-createdat:hover {
  color: black;
  font-weight: bold;
}