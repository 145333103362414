.svg-white path {
  fill: white !important;
}

.modal-link {
  color: white;
  font-weight: bold;
}

.modal-link:hover {
  color: #db2828;
}