.strip-top {
    position: fixed;
    height: 30px;
    min-width: 100%;
    z-index: 100000;
}

.strip-bottom {
    position: fixed;
    bottom: 0;
    height: 30px;
    min-width: 100%;
    z-index: 100000;
}

.development {
    background-image: linear-gradient(45deg, yellow 25%, black 25%, black 50%, yellow 50%, yellow 75%, black 75%, black 100%);
    background-size: 56.57px 56.57px;
}

.staging {
    background-image: linear-gradient(45deg, orange 25%, black 25%, black 50%, orange 50%, orange 75%, black 75%, black 100%);
    background-size: 56.57px 56.57px;
}

.main-content-top-development {
    padding-top: 30px;
}

.main-content-top-staging {
    padding-top: 30px;
}