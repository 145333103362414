.icon-svg {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  padding: 0;
  margin: 0;
}

.icon-svg svg path {
  stroke: #5d5d5d !important;
  fill: #5d5d5d !important;
}