.ui.button.radius {
  border-radius: .28571429rem
}

.powerfailure {
  width: 100%;
  overflow-x: auto;
  margin-top: 1em;
  padding: 5px 0;
}

.powerfailure th {
  font-weight: 400;
  font-size: 1.3em;
}

.text_center {
  text-align: center;
}