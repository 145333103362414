/* .left-menu {
  font-size: .7em;
  height: 100vh;
  max-width: 5%;
  display: inline-flex;
} */

/* .left-menu > .item {
  display: inline-table
} */

.menu-icon-svg > svg {
  max-width: 60%;
  height: auto;
}
