.home {
  min-height: 100vh;
  background-image: url('/assets/images/bg-home.jpg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom center;
  display: flex;
  flex-direction: column;
}

.home .main {
  flex: 1;
}

.home .ui.grid>.column:not(.row),
.home .ui.grid>.row>.column {
  padding-left: 0;
  padding-right: 0;
}

.brandPT {
  font-family: 'Titillium Web', sans-serif !important;
  font-weight: 900 !important;
  font-size: 1.9em !important;
  line-height: 0.9em !important;
  display: block;
  height:100px;
  padding-left: 15px !important;
}

.brandA2A > svg {
  width: 172px;
  height: 60px;
}

.brandLove {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.brandLove > svg {
  height: 130px;
}

.thanks > svg {
  max-width: 250px;
  border-bottom: 3px dotted silver;
  padding-bottom: 2em;
}