.robot {
  width: 404px;
}

.robotNotYeah {
  color: #3e87a6 !important;
  background: #ffffff;
  font-family: 'PixelSplitter';
  padding: 0.5rem;
  /* min-height: 130px; */
  display: flex;
  flex-direction: column;
}

.h1-title {
  color: #ff0000 !important;
}

.title {
  line-height: 60px;
  font-size: 50px;
}

.sub-title {
  line-height: 25px;
}

.container-503 {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}