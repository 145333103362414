.ui.card.mac-card.pending {
    background: #eee;
    opacity: .9
}

.ui.card.mac-card .mac-card__header {
    background: #DFEBF0;
    text-align: center;
}
.ui.card.mac-card.not-visible .mac-card__header .header {
    color: #8e8e8e
}
.ui.card.mac-card .mac-card__actions-container {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.card.mac-card .ui.list>.mac-card-list-item {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    transition: background 300ms;
}
.ui.card.mac-card .ui.list>.mac-card-list-item .mac-card-list-item__content-label {
    display: flex;
    flex: 1 0 50%;
    align-items: center;
    justify-content: flex-start;
}
.ui.card.mac-card .ui.list>.mac-card-list-item .mac-card-list-item__content-value{
    display: flex;
    flex: 1 0 50%;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
}

.ui.card.mac-card .ui.list>.mac-card-list-item.editing:hover {
    background: #DFEBF0;
}

.ui.card.mac-card .ui.list>.mac-card-list-item .mac-card-list-item__content-label,
.ui.card.mac-card .ui.list>.mac-card-list-item.editing .mac-card-list-item__content-value {
    font-weight: bold;
}

.ui.card.mac-card .ui.list>.mac-card-list-item .mac-card-list-item__editable-content {
    flex: 1 0 100%;
    text-align: center;
    margin-top: 1rem
}
.ui.card.mac-card .ui.list>.mac-card-list-item .mac-card-list-item__editable-content > * {
    margin-top: .75rem
}


