/** @format */

.robot {
  width: 404px;
}

.robotNotFound {
  color: #3e87a6 !important;
  background: #ffffff;
  font-family: 'PixelSplitter';
  padding: 0.5rem;
  width: 100%;
  /* min-height: 130px; */
  display: flex;
  flex-direction: column;
}

.container-404 {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: space-around;
  width: 600px;
  margin: 0 auto;
}
