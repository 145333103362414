.robot {
  width:100%;
}

.robotOffer {
  background: #fff;
  color: #000;
  padding: 0.5rem;
  width: 100%;
  min-height: 130px;
}

.offerDescription {
  color: #000000;
  font-size: 1.6em;
}

.offerName {
  color: #F20A27;
  margin-top: 1.5em;
  text-transform: uppercase;
  font-size: 1.2em;
}

.offerPrice {
  color: #F20A27;
  font-size: 1.6em !important;
}

.offerPriceDetails {
}

.offerPriceVat {
  color: #000000;
  font-size: 0.6em !important;
  line-height: 0.6em;
  margin-bottom: 1em;
}

.offerBuyMe {
  font-size: 0.8em !important;
}

.dividerWrap {
  position: relative;
  padding: 0;
  margin: 0;
}

[data-tooltip]:before {
  display: none;
}

[data-tooltip]:after {
  pointer-events: none;
  content: attr(data-tooltip);
  position: absolute;
  text-transform: none;
  text-align: center;
  white-space: nowrap;
  border: 0px;
  max-width: none;
  background-color: transparent;
  background-image: none;
  color: #fff;
  font-size: 0.65rem;
  z-index: 1;
}

[data-position="bottom center"][data-tooltip]:after {
  top: 92%;
}
