.wrapped{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.advice-new {
  color: red;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: small;
  vertical-align: super;
}