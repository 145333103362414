.footer {
  background: rgb(255, 255, 255);
  /* position: absolute; */
  /* width: 100%; */
  /* bottom: 0px; */
  padding: 20px;
  border-top: solid 1px rgb(0, 159, 218);
}

.informations {
  text-align: right;
}
